import bulbasaur from './images/bulbasaur.jpg';
import frog from './images/frog.jpg';
import handlebars from './images/handlebars.jpg';
import house from './images/house.jpg';
import melania from './images/melania.jpg';
import phoneholder from './images/phone.jpg';
import toothbrush from './images/toothbrush.jpg';
import action from './images/action.jpg';
import astro from './images/astro.jpg';
import funko from './images/funko.jpg';
import halloween from './images/halloween.jpg';
import head from './images/head.jpg';
import phoenix from './images/phoenix.jpg';
import tengen from './images/tengen.jpg';

const portfolioItems = [
    {
      imageSrc: tengen,
      altText: 'Tenjin',
      title: 'Tenjin',
      material: 'RESIN',
      description: 'A large multi-part print highly detailed print',
      large: true
    },
    {
      imageSrc: halloween,
      altText: 'Sam',
      title: 'Sam',
      material: 'PLA',
      description: 'Model found on thingiverse and printed using PLA',
      large: false
    },
    {
      imageSrc: bulbasaur,
      altText: 'Bulbasaur',
      title: 'Bulbasaur',
      material: 'PLA',
      description: 'A plant pot holder printed with our FDM printer. Sanded down to reduce layer lines and painted.',
      large: false
    },
    {
      imageSrc: melania,
      altText: 'Melania Bust',
      title: 'Melania Bust',
      material: 'Resin',
      description: 'This was printed with our Elegoo Mars 2 printer. It has great amounts of detail and makes for a perfect gift for an Elden Ring fan.',
      large: false
    },
    {
      imageSrc: frog,
      altText: 'Frog',
      title: 'Frog Plant Holder',
      material: 'PLA',
      description: 'A cute sturdy PLA print that has space for a little plant at the back. 3D modelled, printed and painted by us.',
      large: true
    },
    {
      imageSrc: phoneholder,
      altText: 'Phone Holder',
      title: 'Phone Holder',
      material: 'PLA',
      description: 'A customer order, this is a multi part print from Thingiverse that uses springs and cogs to allow the phone holder to adjust to your phone size.',
      large: false
    },
    {
      imageSrc: head,
      altText: 'Plant pot',
      title: 'Plant pot',
      material: 'PLA',
      description: 'A customer order, a large plant pot, printed with PLA and sanded down and painted.',
      large: true
    },
    {
      imageSrc: house,
      altText: 'Minature House',
      title: 'Minature House',
      material: 'PLA',
      description: 'A customer order of a small version of their house to be put into a snow globe! Printed with our Ender 5 and painted with acrylics',
      large: false
    },
    {
      imageSrc: handlebars,
      altText: 'Handlebars Mold',
      title: 'Bike handle Mold',
      material: 'PLA',
      description: 'A customer order, we printed this model on our Ender 5 using PLA.',
      large: false
    },
    {
      imageSrc: astro,
      altText: 'Astronaut Figure',
      title: 'Astronaut Figure',
      material: 'Resin',
      description: 'A customer order, printed with resin using our Elegoo Mars 2 ',
      large: false
    },
    {
      imageSrc: phoenix,
      altText: 'Phoenix',
      title: 'Phoenix',
      material: 'Resin',
      description: 'A clear resin print, painted with alcohol paints',
      large: true
    },
    {
      imageSrc: action,
      altText: 'Action Figure',
      title: 'Action Figure',
      material: 'PLA',
      description: 'A customer order, this is a multi part, moveable print. We sanded it down, finished and painted it. We printed with PLA for a stronger print',
      large: false
    },
    {
      imageSrc: funko,
      altText: 'Custom Funko Pop',
      title: 'Custom Funko Pop',
      material: 'Resin',
      description: 'Modelled and printed by us, this can make a great gift for Funko Pop lovers',
      large: false
    },
    {
      imageSrc: toothbrush,
      altText: 'Toothbrush Holder',
      title: 'Toothbrush Holder',
      material: 'PLA',
      description: 'A modified model from Thingiverse, great for holding electric toothbrushes, the model comes apart for easy cleaning and has a hole at the bottom to allow water to drain.',
      large: false
    }
  ];
  
  export default portfolioItems;
  